/* globals requestTimeout, showAjaxLoading, hideAjaxLoading, getScannerId, getScannerCompany, getScannerLocationCode, getRequestPath,
 getProgramName */

/**
 *
 * @typedef {Object} warehouseCapacity Capacity details for a warehouse / bay value
 * @property {String} location
 * @property {String} warehouse
 * @property {String} bay
 * @property {String} downloadDate
 * @property {String} downloadSource
 * @property {capacityLocation} capacityLocation a record for every rack / level that has availability
 *
 * @typedef {Object} capacityLocation A bay location in a warehouse with capacity details
 * @property {String} rack
 * @property {String} level optional level the vacuity applies to
 * @property {vacuity} vacuity a vacuity record per type available in that location
 *
 * @typedef {Object} vacuity space available for a given vacuity type.
 * @property {String} vacuityType
 * @property {Number} capacity
 */

/**
 * @description save a new capacity to storage, updating if it already exists.
 * @param {Object} newCapacity
 */
function saveCapacityToStorage(newCapacity) {
	"use strict";
  var currentCapacities = getCapacitiesFromStorage(),
		replaced = false;

	for (var i=0; i<currentCapacities.length; i++) {
		if (currentCapacities[i].location === newCapacity.location &&
				currentCapacities[i].warehouse === newCapacity.warehouse &&
				currentCapacities[i].bay === newCapacity.bay) {
			  currentCapacities[i] = newCapacity;
			replaced = true;
			break;
		}
	}
	if (!replaced) {
		currentCapacities.push(newCapacity);
	}
  localStorage.setObject("capacities",currentCapacities);
}

/**
 * @description returns the capacities held in LocalStorage.
 */
function getCapacitiesFromStorage() {
	"use strict";
  return localStorage.getObject("capacities");
}

/**#
 * @description Downloads a warehouse capacity from the server if any exist
 * @param source {String} Download source, either through 'Find', 'Update', or a program name
 * @param warehouse {String}
 * @param bay {String}
 * @param callback Function to run when done.
 */
function fetchWarehouseCapacity(source, warehouse, bay, callback) {
	"use strict";
	var scanner = getScannerId(),
		company = getScannerCompany(),
		locationCode = getScannerLocationCode();

	showAjaxLoading();

	$.ajax({
		url: getRequestPath("warehouseCapacity?location="+locationCode+"&company=" + company + "&warehouse=" + warehouse + "&scanner=" + scanner+ "&bay=" + bay+ "&source=" + source),
		type: "GET",
		contentType: "application/JSON",
		timeout: requestTimeout,
		xhrFields: {
			withCredentials: true
		},
		success: function (capacityData) {
			hideAjaxLoading();
			if (capacityData && capacityData.capacity) {
				saveCapacityToStorage(capacityData.capacity[0]);
				callback(capacityData.capacity[0]);
			} else {
				callback(null);
			}
		},
		error: function (xhr) {
			hideAjaxLoading();
			switch (xhr.status) {
				case 401:
					if (canReattemptAfterLogin()) {
						renewServerSession(function(success) {
							if (success) {
								fetchWarehouseCapacity(source, warehouse, bay, callback);
							} else {
								callback(null);
							}
						});
					} else {
						callback(null);
					}
					break;
				default:
					callback(null);
					break;
			}
		}
	});
}

/**
 * @description Go through each capacity, either clearing or updating.
 * @param progressElement
 * @param callback
 */
function updateCapacities(progressElement, callback) {
	"use strict";
	progressElement.html("Updating WHS Capacities...");

	var currentCapacities = getCapacitiesFromStorage(),
		timeNow = new Date();

	var expiry = localStorage.getVariable("settings/capacityExpiryValue")*1,
		  expiryUnit = localStorage.getVariable("settings/capacityExpiryUnit");

	// clear expired capacities first.
	currentCapacities.forEach(function(capacity, index, capacityArray) {
		var difference = timeNow - new Date(capacity.downloadDate);
    var expiryInMs = calculateExpiryTimeInMs(expiryUnit, expiry);

		if (difference >= expiryInMs) {
			capacityArray.splice(index, 1);
		}
	});

	// After clearing expired ones, we update the LS
	localStorage.setObject("capacities",currentCapacities);
	if (currentCapacities.length === 0) {
		callback();
		return;
	}

	// As we cannot use any async await for js in scanners, we need to create a loop function for waiting.
	var capacityIndex = 0;
	var loopAndUpdate = function(callback) {
		if (capacityIndex < currentCapacities.length) {
			// continue as we have not overflowed.
			progressElement.html("Capacity " + (capacityIndex+1) + " of " + currentCapacities.length);
			fetchWarehouseCapacity("Update",
				currentCapacities[capacityIndex].warehouse,
				currentCapacities[capacityIndex].bay, function() {
				// Increment the index and recall self.
					capacityIndex ++;
					loopAndUpdate(callback);
			});
		} else {
			callback();
		}
	};
	loopAndUpdate(callback);

}

/**
 * @description Work out the expiry time to use for checking if a capacity has expired
 * @param expiryUnit {String} unit of measure, either 'hours' or 'days'
 * @param expiry {Number} value of the expiry
 * @returns {number} MS of the expiry time. Note that if input had been 1 day, then this is MS since midnight.
 */
function calculateExpiryTimeInMs(expiryUnit, expiry) {
	"use strict";
	switch(expiryUnit) {
		case "hours":
			return (expiry * 1000 * 60 * 60);  // time in ms for hours
		case "days":
			// Gets tricky with days as the calculation needs to cater for the fact we are probably part way through today.
			// first get ms since midnight
			var timeNow = new Date(),
				  midnight = new Date(timeNow.getFullYear(), timeNow.getMonth(), timeNow.getDate());
			// return today's ms + ms for every full day before today if days is greater than 1
			return (timeNow - midnight) + ((expiry - 1) * 1000 * 60 * 60 * 24);
		default:
			return 0;
	}
}

/**
 * @description Checks a capacity for a given location, and then returns its vacuity records in callback
 * @param warehouse
 * @param bay
 * @param rack
 * @param level {String} optional - Should be blank if the location is in a palletised warehouse.
 * @param callback
 */
function checkCapacityForLocation(warehouse, bay, rack, level, callback) {
	"use strict";
	var currentCapacities = getCapacitiesFromStorage();
	var requiredCapacity = currentCapacities.filter(function(el) {
		return el.warehouse === warehouse && el.bay === bay;
	})[0];

	if (requiredCapacity) {
		// we have the capacity record, so lets check for a rack and level match too.
		callback(getVacuityRecords(requiredCapacity.capacityLocation, rack, level));
		// level check only for racked warehouses.
	} else {
		// no record was found so we need to try and download it.
		fetchWarehouseCapacity(getProgramName(), warehouse, bay, function(data) {
			if (data) {
				callback(getVacuityRecords(data.capacityLocation, rack, level));
			} else {
				callback([]);
			}
		});
	}
}

/**
 * @description Fetches the vacuity records from a capacity, returning an array of vacuity types and capacities.
 * @param capacityLocations
 * @param rack
 * @param level
 * @returns {*}
 */
function getVacuityRecords(capacityLocations, rack, level) {
	"use strict";
	var requiredVacuity = capacityLocations.filter(function(el) {
		return el.rack === rack && el.level === level;
	})[0];
	if (requiredVacuity) {
		return requiredVacuity.vacuity;
	} else {
		return [];
	}
}

/**
 * @description Removes a capacity from localStorage at the given warehouse and bay.
 * @param warehouse
 * @param bay
 * @param callback
 */
function removeCapacityFromStorage(warehouse, bay, callback) {
	"use strict";
  var currentCapacities = getCapacitiesFromStorage();

	for (var i=0; i<currentCapacities.length; i++) {
		if (currentCapacities[i].warehouse === warehouse &&
			currentCapacities[i].bay === bay) {
			currentCapacities.splice(i, 1);
			break;
		}
	}
	localStorage.setObject("capacities",currentCapacities);
	callback(currentCapacities);
}

/**
 * @description Removes any downloaded capacities from the system.
 */
function clearCapacities() {
	"use strict";
	localStorage.removeItem("capacities");
}
